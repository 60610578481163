import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)


app.use(router)

// 路由变化修改页面titlt
router.beforeEach((to,from,next)=>{
    if(to.meta.title){
        document.title = to.meta.title
    }
    next()
})

app.mount('#app')
