<template>
    <div>
        <div class="section frame8">
            <div class="pack pot8">
                <div class="c-left">
                    <p class="c-head">联系我们</p>
                    <p class="char5">
                        任何关于软机的意见与建议，以及相关合作事宜，欢迎联系我们
                    </p>
                </div>
                <div class="c-right">
                    <div class="type1">
                        <div class="type1-lt">
                            <p class="way">微信</p>
                            <div class="w-det">
                                <text>微信请添加</text>
                                <text>atyoubj</text>
                            </div>
                            <!-- <p class="w-det">
                                <text>微信请添加</text>
                                <text>atyoubj</text>
                            </p> -->
                        </div>
                        <img class="type1-rt" src="@/assets/Wechat.png" alt="">
                    </div>
                    <div class="type1">
                        <div class="type1-lt">
                            <p class="way">QQ</p>
                            <div class="w-det">
                                <text>QQ请添加</text>
                                <text>570812079</text>
                            </div>
                            <!-- <p class="w-det">
                                <text>QQ请添加</text>
                                <text>570812079</text>
                            </p> -->
                        </div>
                        <img class="type1-rt" src="@/assets/QQ.png" alt="">
                    </div>
                    <div class="type1">
                        <div class="type1-lt">
                            <p class="way">邮箱</p>
                            <div class="w-det">
                                <text>请发送邮件至</text>
                                <text>king@jinsec.com</text>
                            </div>
                            <!-- <p class="w-det">
                                <text>请发送邮件至</text>
                                <text>king@jinsec.com</text>
                            </p> -->
                        </div>
                        <img class="type1-rt" src="@/assets/Email.png" alt="">
                    </div>
                    <div class="type1">
                        <div class="type1-lt">
                            <p class="way">地址</p>
                            <div class="w-det">
                                北京市海淀区海淀路19-1号中成大厦11楼
                            </div>
                            <!-- <p class="w-det">
                                北京市海淀区海淀路19-1号中成大厦11楼
                            </p> -->
                        </div>
                        <img class="type1-rt" src="@/assets/Location.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.frame8 {
    width: 100%;
    height: 1202px;
    background: url("@/assets/banner-14.png") no-repeat;
    background-size: cover;
    background-position: 0 100%;
    background-color: #FFFFFF;
}

.pot8 {
    display: flex;
    justify-content: space-between;

    .c-left {
        margin-top: 84px;
        .c-head {
            font-size: 34px;
            font-family: MicrosoftYaHei;
            font-weight: bold;
            color: #000000;
            margin-bottom: 30px;
        }

    }

    .c-right {
        width: 500px;
        height: 430px;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        box-sizing: border-box;
        padding: 30px;
        margin-top: 84px;
        box-shadow: 5px 0 20px #dbdddf;
        display: flex;
        flex-direction: column;
    }
}

.type1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 25%;

    &:hover{
        background: #EDF0F5;
        cursor: pointer;
    }

    &:not(:nth-last-child(1)){
        position: relative;
        &::after{
            content: '';
            width: 100%;
            height: 1px;
            background-color: #E4E6EB;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .type1-lt {
        flex: 1;
        .way {
            font-size: 24px;
            font-family: MicrosoftYaHei;
            font-weight: bold;
            color: #000000;
        }

        .w-det {
            font-size: 18px;
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: 28px;
            margin-top: 5px;
            &>text{
                display: inline-block;
            }
            &>text:nth-child(1){
                width: 150px;
            }
            &>text:nth-child(2){
                color: #CA1C28;
            }
        }
    }

    .type1-rt{
        height: 100%;
    }
}
</style>