<template>
    <div>
        <div class="banner banner2">
            <div class="container4">
                <div class="char3">
                    「 产品介绍 」
                </div>
                <div class="char4">
                    安卓RPA机器人
                </div>
                <p class="char5">
                    RPA是利用软件来执行业务流程的一组技术，按照人类的执行规则和操作操作过程来执行同样的流程。
                </p>
            </div>
        </div>
        <div class="content2">
            <div class="container5">
                <div class="char6">
                    定制AOSP安卓手机
                </div>
                <div class="char7">
                    选择Redmi 10A的六大理由
                </div>
                <div class="box4">
                    <div class="block1">
                        <div class="lt4">
                            <div class="txs">
                                <text class="tx1">5000mAh</text>
                                <text class="tx2">充电宝级大电量</text>
                            </div>
                            <div class="tx3">01</div>
                        </div>
                        <img class="img-1" src="@/assets/img-4.png" alt="">
                    </div>
                    <div class="block2">
                        <div class="lt4">
                            <div class="txs">
                                <text class="tx1">1300万</text>
                                <text class="tx2">高清主摄</text>
                            </div>
                            <div class="tx3">02</div>
                        </div>
                        <img class="img-2" src="@/assets/img-5.png" alt="">
                    </div>
                </div>
                <div class="box5">
                    <div class="lt3">
                        <div class="lt4">
                            <div class="txs">
                                <text class="tx1">Helio G25</text>
                                <text class="tx2">八核处理器</text>
                            </div>
                            <div class="tx3">03</div>
                        </div>
                        <!-- <img class="img-2" src="@/assets/img-5.png" alt=""> -->
                    </div>
                    <div class="rt5">
                        <div class="box6">
                            <div class="block3">
                                <div class="lt4">
                                    <div class="txs">
                                        <text class="tx1">6.53英寸</text>
                                        <text class="tx2">护眼大屏*</text>
                                    </div>
                                    <div class="tx3">04</div>
                                </div>
                                <img class="img-3" src="@/assets/img-6.png" alt="">
                            </div>
                            <div class="block4">
                                <div class="lt4">
                                    <div class="txs">
                                        <text class="tx1">测试严苛</text>
                                        <text class="tx2">品质可安心</text>
                                    </div>
                                    <div class="tx3">05</div>
                                </div>
                                <img class="img-4" src="@/assets/img-7.png" alt="">
                            </div>
                        </div>
                        <div class="block5">
                            <div class="lt4">
                                <div class="txs">
                                    <text class="tx1">1217</text>
                                    <text class="tx2">大音量扬声器</text>
                                </div>
                                <div class="tx3">06</div>
                            </div>
                            <img class="img-5" src="@/assets/img-9.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content3">
            <div class="container6">
                <div class="ln-1">
                    <p class="tit4">5000mAh 大电量 出门在外不焦虑</p>
                    <img class="img-6" src="@/assets/img-10.png" alt="">
                </div>
                <div class="ln-2">
                    <p class="tit5">
                        超大电量可以连听一周的音乐、<br />持续追剧从日出到日落;<br />
                        从此出门放轻松，再也不必为电量焦虑。
                    </p>
                    <table class="table1">
                        <tr>
                            <td>
                                <text class="tx4">35</text><text class="tx5">天</text>
                                <text class="tx6">日常待机</text>
                            </td>
                            <td>
                                <text class="tx4">6</text><text class="tx5">天</text>
                                <text class="tx6">音乐播放</text>
                            </td>
                            <td>
                                <text class="tx4">19</text><text class="tx5">时</text>
                                <text class="tx6">视频播放</text>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <text class="tx4">18</text><text class="tx5">小时</text>
                                <text class="tx6">导航</text>
                            </td>
                            <td>
                                <text class="tx4">32</text><text class="tx5">小时</text>
                                <text class="tx6">通话</text>
                            </td>
                            <td>
                                <text class="tx4">33</text><text class="tx5">小时</text>
                                <text class="tx6">小说阅读</text>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="content4">
            <div class="content5">
                <div class="container7">
                    <p class="tit6">Helio G25 八核处理器</p>
                    <p class="tit7">低功耗更省电<br />游戏畅玩不卡顿</p>
                    <p class="tit8">Helio G25选用更低功耗策略。<br />
                        流畅不卡顿，游戏酣战也可稳定输出。
                    </p>
                </div>
            </div>
        </div>
        <div class="content6">
            <div class="content7">
                <div class="container8">
                    <img class="img-7" src="@/assets/img-11.png" alt="">
                    <div class="tx7">
                        <p class="tit9">6.53"大尺寸屏幕</p>
                        <p class="tit-10">为你带来「大幅」的精彩</p>
                        <p class="tit-11">
                            6.53英寸屏幕，追剧看电影，观看轻松也清晰；<br />支持大字阅读，聊天、看书更省力。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content8">
            <div class="container9">
                <div class="lt5">
                    <p class="tit6">1300万超清主摄<br />500万前置AI人像</p>
                    <p class="tit7">拍风景、拍合照，统统拿捏</p>
                    <p class="tit8">1300万后置主摄，为你定格更逼真的影像;<br />
                        500万前置，人像大片一拍即得。
                    </p>
                    <div class="tags">
                        <div class="tag">AI相机5.0</div>
                        <div class="tag">魔法万花筒</div>
                    </div>
                </div>
                <img class="img-8" src="@/assets/img-12.png" alt="">
            </div>
        </div>
        <div class="content9">
            <div class="container-10">
                <div class="ln-3">
                    <img class="img-9" src="@/assets/img-13.png" alt="">
                    <p class="title-1">智能优化<text class="num">27种</text>场景</p>
                    <p class="title-2">不辜负任何一道美景，包括你。</p>
                </div>
                <div class="ln-4">
                    <p class="title-1"><text class="num">1300万</text>超清影像</p>
                    <p class="title-2">将大千世界，尽收眼底</p>
                    <img class="img-9" src="@/assets/img-14.png" alt="">
                </div>
            </div>
        </div>
        <div class="content-10">
            <div class="container-11">
                <p class="tit9">1217大音量扬声器+大尺寸听筒</p>
                <p class="tit-10">世界再喧闹，你的声音也能听到</p>
                <p class="tit-11">
                    1217带来的大音腔，让扬声器声音播放更加“嘹亮”，<br />
                    大尺寸听筒，即使身处喧嚣环境，也能清楚听到电话里面的每句问候。
                </p>
                <img style="width: 1170px;height: 552px;" src="@/assets/img-15.png" alt="">
            </div>
        </div>
        <div class="content-11">
            <div class="container-12">
                <table class="table2">
                    <tr>
                        <td class="td1">
                            <p class="tx8">500,000<text class="tx9">次</text></p>
                            <p class="tx-10">电源键测试</p>
                        </td>
                        <td class="td1">
                            <p class="tx8">10,000<text class="tx9">次</text></p>
                            <p class="tx-10">Micro USB 插拔耐久</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="td1">
                            <p class="tx8">300<text class="tx9">次</text></p>
                            <p class="tx-10">极限滚筒测试</p>
                        </td>
                        <td class="td1">
                            <p class="tx8">70<text class="tx9">KG</text></p>
                            <p class="tx-10">重物静压</p>
                        </td>
                    </tr>
                </table>
                <div class="rt6">
                    <div style="margin-left: 90px;padding-top: 10px;">
                        <p class="tit6">历经严苛品质测试</p>
                        <p class="tit7">经得起摔打，扛得住重压</p>
                        <p class="tit8">通过了“旗规机”标准的品质测试，<br />
                            抗摔、耐磨，经得起考验。
                        </p>
                    </div>

                </div>
                <img class="img-10" src="@/assets/img-16.png" alt="">
            </div>
        </div>
        <div class="content-12">
            <div class="container-13">
                <p>自研RPA引擎</p>
                <div class="box7">
                    <p class="panel">
                        RPA引擎主要是解释并执行软件机器人脚本。<br />
                        每个脚本都是对应某一个应用撰写的。<br />
                        脚本由作业组成，每个作业都定了触发的类、函数和参数。<br />
                        并且可以指定需要延时或周期执行的时间。
                    </p>
                    <p class="panel">
                        每个作业由不同的动作组成。<br />
                        一连串的动作就构成了整个自动化过程。动作分为不同的类型，比如常见的上报、视图树、点击、双击、下拉、打开APP、跳转等。<br />
                        每一种动作都有对应的属性值。在定义动作的时候，需要编辑需要的属性。比如，点击动作，可以设置点击的x和y坐标。<br />
                        或者也可以通过定义视图列表，来指定要点击的具体视图。
                    </p>
                </div>
            </div>
        </div>
        <div class="last">
            <div class="last-cn">
                <p>智能脚本管理平台</p>
                <div class="tags-1">
                    <div class="tag-1">安全检测</div>
                    <div class="tag-1">数据采集</div>
                </div>
                <div class="tags-1">
                    <div class="tag-1">任务执行</div>
                    <div class="tag-1">辅助办公</div>
                    <div class="tag-1">K12教育</div>
                </div>
                <div class="tags-1">
                    <div class="tag-1">游戏防沉溺</div>
                    <div class="tag-1">养老助残</div>
                    <div class="tag-1">...</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script></script>
<style lang="scss" scoped>
.char3,
.char4,
.tx1,
.tit4,
.tit5,
.tx6,
.tx5,
.tit6,
.tit7,
.tit8,
.tit9,
.tit-10,
.tit-11,
.tag,
.title-1,
.title-2,
.tx9,
.tx-10,
.panel,
.tag-1 {
    font-family: MicrosoftYaHei;
}

.banner2,
.content2,
.content3,
.content4,
.content5,

.content7,
.content8,
.content9,
.content-10,
.content-11,
.content-12,
.last,
.last-cn {
    display: flex;
    justify-content: center;
}

.container4,
.container5,
.container6,
.container7,
.container8,
.container9,
.container-10,
.container-11,
.container-12,
.container-13,
.last-cn {
    width: 1146px;
    height: 100%;
}

.banner2 {
    background: url("@/assets/banner-2.png") no-repeat;
    background-size: cover;
    background-position: 50% 100%;
}

// .char3 {
//     font-size: 30px;
//     font-weight: bold;
//     color: #AB101B;
//     margin-top: 80px;
// }

// .char4 {
//     font-size: 34px;
//     font-weight: bold;
//     color: #262626;
//     margin: 20px 0;
// }

// .char5 {
//     width: 423px;
//     font-size: 16px;
//     color: #666666;
// }

// 中间-1
.content2 {
    width: 100%;
    height: 986px;
    background: #000000;
    box-sizing: border-box;
}

.char6,
.char7 {
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
}

.char6 {
    font-size: 40px;
    margin: 60px 0;
}

.char7 {
    font-size: 28px;
    margin: 30px 0;
}

.box4,
.box5,
.box6 {
    display: flex;
    justify-content: space-between;
}

.block1,
.block2,
.block3,
.block4,
.block5 {
    background: linear-gradient(to right, #240507 30%, #1B0203 70%);
    display: flex;
    justify-content: space-between;
}

.lt4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;

    .txs {

        .tx1,
        .tx2 {
            display: block;
        }

        .tx1 {
            font-size: 20px;
            font-weight: bold;
            color: #CA1C28;
        }

        .tx2 {
            font-size: 18px;
            color: #FFFFFF;
        }
    }

    .tx3 {
        font-size: 70px;
        font-family: HelveticaNeue, HelveticaNeue;
        font-weight: 900;
        color: transparent;
        -webkit-text-stroke: 1px #AB101B;
        opacity: 0.4;
    }
}


.block1 {
    width: 762px;
    height: 220px;
    box-sizing: border-box;
    padding: 20px 30px 0;

    .img-1 {
        width: 452px;
        height: 172px;
        margin: 0 44px 0 0;
    }
}

.block2 {
    width: 376px;
    height: 220px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-top: 20px;

    .img-2 {
        width: 225px;
        height: 183px;
        margin-top: 17.5px;
        margin-right: -0.5px;
    }
}

.box5 {
    box-sizing: border-box;
    margin: 10px 0;

    .lt3 {
        width: 376px;
        height: 450px;
        box-sizing: border-box;
        padding-left: 20px;
        padding-top: 20px;
        background: linear-gradient(to top right, #38070A 30%, #1B0203 70%);

        .lt4 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 420px;
            background: url("@/assets/img-8.png") no-repeat;
            background-size: 100% 100%;
        }
    }

    .rt5 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.box6 {

    .block3,
    .block4 {
        width: 376px;
        height: 220px;
        box-sizing: border-box;
        padding-left: 20px;
        padding-top: 20px;
    }
}

.img-3 {
    width: 223px;
    height: 189px;
    margin-top: 11.5px;
}

.img-4 {
    width: 148px;
    height: 183px;
    margin-top: 17.5px;
    margin-right: -0.5px;
}

.block4 {
    width: 376px;
    height: 220px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-top: 20px;
}

.block5 {
    width: 762px;
    height: 220px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-top: 20px;

    .img-5 {
        width: 405px;
        height: 202px;
        margin-top: -1.5px;
    }
}

// 中间-2
.content3 {
    width: 100%;
    height: 480px;
    background: #FFFFFF;

    .container6 {
        display: flex;
        box-sizing: border-box;
        padding-top: 80px;

        .ln-1 {
            width: 680px;

            .tit4 {
                width: 258px;
                font-size: 30px;
                font-weight: bold;
                color: #000000;
                line-height: 42px;
                margin-left: 60px;
            }

            .img-6 {
                width: 662px;
                height: 202px;
                margin-top: 60px;
            }
        }

        .ln-2 {
            width: 466px;

            .tit5 {
                font-size: 16px;
                color: #666666;
                line-height: 26px;
            }

            .table1 {
                margin-top: 50px;
                width: 100%;
                height: 202px;

                .tx4,
                .tx5 {
                    display: inline-block;
                    color: #CA1C28;
                }

                .tx4 {
                    font-size: 40px;
                    font-family: HelveticaNeue, HelveticaNeue;
                    font-weight: bolder;
                }

                .tx5 {
                    font-size: 20px;
                }

                .tx6 {
                    display: block;
                    font-size: 16px;
                    color: #333333;
                }
            }
        }
    }
}

// 中间-3
.content4 {
    width: 100%;
    height: 480px;
    background-color: #000000;

    .content5 {
        width: 1260px;
        height: 480px;
        background: url("@/assets/banner-3.png") no-repeat;
        background-size: 100% 100%;
    }


}

.tit6 {
    font-size: 30px;
    font-weight: bold;
    color: #CA1C28;
    margin-top: 80px;
    margin-bottom: 15px;
}

.tit7 {
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 42px;
    margin-bottom: 40px;
}

.tit8 {
    font-size: 16px;
    color: #FFFFFF;
    line-height: 26px;
    opacity: 0.4;
}

.tit9 {
    font-size: 30px;
    font-weight: bold;
    color: #CA1C28;
    margin-top: 83px;
}

.tit-10 {
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    margin: 15px 0;
}

.tit-11 {
    font-size: 16px;
    color: #666666;
    line-height: 26px;
    margin-top: 40px;
}

// 中间-4
.content6 {
    width: 100%;
    height: 480px;
    background: linear-gradient(to right, #1C5770 300px, #FFFFFF 600px);

    .content7 {
        height: 100%;
        background: url("@/assets/banner-4.png") no-repeat;
        background-size: 1162px 480px;

        .container8 {
            display: flex;

            .img-7 {
                width: 711px;
                height: 343px;
                margin-top: 137.5px;
            }

            .tx7 {
                margin-left: -30px;
            }
        }
    }
}

// 中间-5
.content8 {
    width: 100%;
    height: 480px;
    background: linear-gradient(to bottom, #110c0c 20%, #36080b 100%);

    .container9 {
        display: flex;
        justify-content: space-between;

        .lt5 {
            .tags {
                display: flex;
                margin-top: 60px;

                .tag {
                    width: 200px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border: 1px solid;
                    border-color: rgba(255, 255, 255, 0.2);
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-right: 10px;
                }
            }
        }

        .img-8 {
            width: 609px;
            height: 482px;
        }
    }
}

// 中间-6
.content9 {
    width: 100%;
    height: 740px;

    .container-10 {
        display: flex;
        box-sizing: border-box;
        padding: 50px 0;

        .ln-3,
        .ln-4 {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .ln-3 {
            justify-content: flex-start;
        }

        .ln-4 {
            justify-content: flex-end;
        }
    }
}

.img-9 {
    width: 630px;
    height: 439px;
}

.title-1 {
    font-size: 30px;
    font-weight: bold;
    color: #CA1C28;
    margin: 10px 0 10px 40px;

    .num {
        font-size: 40px;
    }
}

.title-2 {
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    margin-left: 40px;
}

// 中间-7
.content-10 {
    width: 100%;
    height: 830px;
    background-color: #E6EBF2;

    .container-11 {
        text-align: center;
    }
}

// 中间-8
.content-11 {
    width: 100%;
    height: 480px;
    background: linear-gradient(to bottom, #000000 20%, #0a2c42 100%);
    position: relative;

    .container-12 {
        display: flex;
        box-sizing: border-box;

        .table2 {
            flex: 0.5;
            height: 260px;
            border-spacing: 10px;
            border-collapse: separate;
            margin-top: 80px;

            .td1 {
                width: 250px;
                height: 100px;
                background: rgba(241, 244, 249, 0.15);
                box-sizing: border-box;
                padding: 0 18px;

                .tx8 {
                    font-size: 40px;
                    font-family: HelveticaNeue, HelveticaNeue;
                    font-weight: bolder;
                    color: #CA1C28;

                    .tx9 {
                        font-size: 20px;
                        color: #CA1C28;
                        font-weight: normal;
                    }
                }

                .tx-10 {
                    font-size: 16px;
                    color: #FFFFFF;
                }
            }
        }

        .rt6 {
            flex: 0.5;
            height: 100%;
            box-sizing: border-box;
        }
    }

    .img-10 {
        width: 1002px;
        height: 208px;
        position: absolute;
        bottom: -125px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.content-12 {
    width: 100%;
    height: 480px;
    background-color: #FFFFFF;

    .container-13 {
        box-sizing: border-box;
        padding-top: 90px;

        &>p {
            font-size: 40px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            color: #000000;
            text-align: center;
            margin: 40px 0;
        }

        .box7 {
            width: 100%;
            display: flex;

            .panel {
                flex: 0.5;
                font-size: 16px;
                color: #333333;
                line-height: 30px;
            }
        }
    }
}

// last
.last {
    width: 100%;
    height: 360px;
    background: url("@/assets/banner-5.png") no-repeat;
    background-size: 100% 101%;

    .last-cn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        &>p {
            font-size: 40px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            color: #FFFFFF;
            margin: 60px 0;
        }

        .tags-1 {
            display: flex;
            align-items: center;
            

            .tag-1 {
                padding: 0 35px;
                height: 40px;
                line-height: 40px;
                background: rgba(139, 18, 26, 1);
                font-size: 20px;
                opacity: 0.8;
                color: #FFFFFF;
                margin-bottom: 10px;
            }
        }
        .tags-1>.tag-1:not(:nth-last-child(1)){
            margin-right: 10px;
        }
    }
}
</style>