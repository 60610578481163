<template>
    <div>
        <div class="banner1">
            <div class="container1">
                <div class="char1">
                    「 软机 」
                </div>
                <div class="char2">
                    基于安卓系统的机器人 流程自动化平台
                </div>
                <div class="box1">
                    <div class="box1-it">
                        <div class="lt1">
                            <div class="tit1">
                                为企业
                            </div>
                            <div class="tit2">
                                降本增效
                            </div>
                        </div>
                        <div class="rt1">
                            <img src="@/assets/raise.png" alt="">
                        </div>
                    </div>
                    <div class="box1-it">
                        <div class="lt1">
                            <div class="tit2">
                                代替人工
                            </div>
                            <div class="tit1">
                                重复性工作
                            </div>
                        </div>
                        <div class="rt1">
                            <img src="@/assets/repeat.png" alt="">
                        </div>
                    </div>
                    <div class="box1-it">
                        <div class="lt1">
                            <div class="tit2">
                                专业团队

                            </div>
                            <div class="tit1">
                                研发
                            </div>
                        </div>
                        <div class="rt1">
                            <img src="@/assets/major.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container2">
            <div class="tab1">
                <div @click="tab1Change(0)" class="tab1-it" :class="{ active2: tab1 == 0 }">
                    Abot
                </div>
                <div @click="tab1Change(1)" class="tab1-it" :class="{ active2: tab1 == 1 }">
                    语音识别
                </div>
                <div @click="tab1Change(2)" class="tab1-it" :class="{ active2: tab1 == 2 }">
                    交互
                </div>
            </div>
        </div>
        <div class="content1" v-show="tab1 == 0">
            <div class="container3">
                <div class="lt2">
                    <div class="tit3">什么是Abot?</div>
                    <div class="line">
                        <div class="short"></div>
                    </div>
                    <p class="p-text">Abot是一款基于开源AOSP的定制化安卓派生系统。<br />
                        它在安卓Instrumentation基础上实现了自定义RPA引擎，<br />完全兼容运行各类安卓派生系统的APP应用。<br />
                        通过自定义脚本，各类智能终端或云平台都可以操控Abot的运行，<br />为机机交互提供了规范化的接口平台。<br />
                    </p>
                </div>
                <img class="rt2" src="@/assets/img-1.png" alt="">
            </div>
        </div>
        <div class="content1" v-show="tab1 == 1">
            <div class="container3">
                <div class="lt2">
                    <div class="tit3">智能语音识别</div>
                    <div class="line">
                        <div class="short"></div>
                    </div>
                    <p class="p-text">
                        智能语音识别可以帮助Abot理解常规机主命令，<br />并通过智能算法自动化完成在手机上的各类操作。<br />对于敏感操作会与机主进一步确认操作。<br />
                        实现了完全独立运行的个人助理应用，<br />可以满足特定人群对于安卓辅助功能的需求。
                    </p>
                </div>
                <img class="rt3" src="@/assets/img-2.png" alt="">
            </div>
        </div>
        <div class="content1" v-show="tab1 == 2">
            <div class="container3">
                <div class="lt2">
                    <div class="tit3">机机交互接口</div>
                    <div class="line">
                        <div class="short"></div>
                    </div>
                    <p class="p-text">
                        基于机机交互接口，通用人工智能可以接入Abot系统，<br />实现与APP的交互。<br />通过接收和理解实时化的APP数据，<br />可以优化大模型基于现实生活场景的智能算法，<br />
                        实现了完全独立运行的个人助理应用，<br />可以满足特定人群对于安卓辅助功能的需求。
                        提升AIGC的创新能力。
                    </p>
                </div>
                <img class="rt4" src="@/assets/img-3.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tab1: 0,
        }
    },
    methods: {
        tab1Change(val) {
            this.tab1 = val
        }
    }
}
</script>

<style lang="scss" scoped>
.char1,
.char2,
.tab1-it,
.tit3,
.fw-it {
    font-family: MicrosoftYaHei, MicrosoftYaHei;
}

.box1-it,
.tab1-it,
.fw-it {
    cursor: pointer;
}

.banner1 {
    background: url("@/assets/banner-1.png") no-repeat;
    background-size: cover;
    background-position: 50% 100%;
    width: 100%;
    height: 480px;
    display: flex;
    justify-content: center;
}

.container1 {
    width: 1146px;
    height: 100%;
    position: relative;
}

.char1 {
    font-size: 30px;
    font-weight: bold;
    color: #FF4B58;
    margin-top: 104px;
}

.char2 {
    width: 358px;
    font-size: 34px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 20px;
}

.box1 {
    width: 1148px;
    height: 120px;
    background: #FFFFFF;
    opacity: 0.9;
    position: absolute;
    bottom: 30px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .box1-it {
        width: 33.3%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;

        &:not(:nth-last-child(1)) {
            position: relative;

            &::after {
                content: '';
                width: 1px;
                height: 60px;
                background: #D9D9D9;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
        }

        .lt1 {
            display: flex;
            flex-direction: column;

            .tit1,
            .tit2 {
                font-size: 20px;
                font-family: MicrosoftYaHei;
                color: #000000;
            }

            .tit2 {
                font-weight: bold;
            }
        }

        .rt1 {
            width: 62px;
            height: 62px;

            &>img {
                width: 62px;
                height: 62px;
            }
        }
    }
}

.container2 {
    background-color: #FFFFFF;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
}

.tab1 {
    width: 1146px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tab1-it {
        width: 382px;
        height: 100%;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: #AB101B;
            color: #FFFFFF;
        }
    }

    .active2 {
        background-color: #AB101B;
        color: #FFFFFF;
    }
}

.content1 {
    background-color: #F2F6FA;
    display: flex;
    justify-content: center;

    .container3 {
        width: 1146px;
        box-sizing: border-box;
        padding: 60px 0 0;
        display: flex;
        justify-content: space-between;

        .lt2 {
            display: flex;
            flex-direction: column;
            padding-top: 30px;

            .tit3 {
                font-size: 24px;
                font-weight: bold;
                color: #000000;
            }

            .line {
                width: 500px;
                height: 1px;
                background: #DADDE0;
                margin: 10px 0;
                position: relative;

                .short {
                    width: 80px;
                    height: 3px;
                    background: #AB101B;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .p-text {
                width: 500px;
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #404040;
                line-height: 30px;
                margin: 20px 0;
            }
        }

        .rt2 {
            width: 621px;
            height: 400px;
        }

        .rt3{
            width: 523px;
            height: 400px;
        }
        .rt4{
            width: 629px;
            height: 400px;
        }
    }
}

</style>