<template>
    <div>
        <div class="banner section">
            <div class="pack">
                <div class="char3">
                    「 解决方案 」
                </div>
                <div class="char4">
                    为小微企业降本增效<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;助力行业智能化转型
                </div>
            </div>
        </div>
        <div class="section sec1">
            <div class="pack pac1">
                <div class="plate1">
                    <p class="topic">知识产权侵权线索追踪</p>
                    <div class="plate2">
                        <p>我是一名<text>小律师</text></p>
                        <p>主要领域是做<text>知识产权侵权案件</text></p>
                    </div>
                    <div class="plate3">
                        <p>今天客户要调查自家品牌</p>
                        <p>在各APP的<text>侵权线索</text></p>
                    </div>
                    <div class="plate4">
                        <p>老板让把主流APP的侵权商品销售情况汇总给他</p>
                    </div>
                    <div class="plate5">
                        <p>多亏有了<text>"软机"</text>这个<text>RPA</text>利器</p>
                    </div>
                </div>
                <img class="pic1" src="@/assets/soimg-2.png" alt="">
            </div>
        </div>
        <div class="section sec2">
            <div class="pack pac2">
                <p class="topic">学生手机防沉迷远程管控</p>
                <div class="mid1">
                    <div class="t-img">
                        红米10A 学生管控定制机
                    </div>
                    <p>戒除网瘾 防止沉迷</p>
                    <div class="two">
                        <span class="left">
                            实时查看使用数据
                            <i></i>
                        </span>
                        <span class="right">一键远程锁屏</span>
                    </div>
                    <img class="mid-img" src="@/assets/soimg-4.png" alt="">
                </div>
                <div class="below">
                    <div class="tip">孩子沉迷手机怎么办？</div>
                    <div class="three">
                        <div class="it">
                            <p>给？怕他玩游戏！</p>
                            <p>沉迷手机、甚至不想学习</p>
                        </div>
                        <div class="it">
                            <p>不给？怕他玩游戏！</p>
                            <p>在家学习、出门都要手机</p>
                        </div>
                        <div class="it">
                            <p>给？怕他玩游戏！</p>
                            <p>还担心他晚上偷偷玩手机？</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section sec3">
            <div class="pack pac3">
                <p class="topic2">专为学生设计</p>
                <p class="topic3">培养自律好习惯</p>
                <div class="box8">
                    <div class="plate6">
                        <img class="icon1" src="@/assets/soicon-1.png" alt="">
                        <div class="para">
                            <p>能决定手机怎么用</p>
                            <p>能用什么？能用多久？家长说了算</p>
                        </div>
                    </div>
                    <div class="plate6">
                        <img class="icon1" src="@/assets/soicon-2.png" alt="">
                        <div class="para">
                            <p>知道孩子去了哪里</p>
                            <p>精准定位，查看移动的轨迹</p>
                        </div>
                    </div>
                    <div class="plate6">
                        <img class="icon1" src="@/assets/soicon-3.png" alt="">
                        <div class="para">
                            <p>能看到孩子用什么</p>
                            <p>查看使用记录，软件时长，时段</p>
                        </div>
                    </div>
                    <div class="plate6">
                        <img class="icon1" src="@/assets/soicon-4.png" alt="">
                        <div class="para">
                            <p>防刷机 防破解</p>
                            <p>绑定无法卸载/删除</p>
                        </div>
                    </div>
                </div>
                <img class="pac3-img" src="@/assets/soimg-5.png" alt="">
            </div>
        </div>
        <div class="section sec4">
            <div class="pack pac4">
                <p class="topic2">
                    孩子沉迷手机 家长非常担心
                </p>
                <p class="topic3">
                    能学习，可绘制，戒网瘾
                </p>
                <div class="box9">
                    <div class="plate7">
                        <img class="icon2" src="@/assets/soicon-5.png" alt="">
                        <div class="para2">
                            沉迷网络<br />情绪暴躁
                        </div>
                    </div>
                    <div class="plate7">
                        <img class="icon2" src="@/assets/soicon-6.png" alt="">
                        <div class="para2">
                            性格孤僻<br />恶习养成
                        </div>
                    </div>
                    <div class="plate7">
                        <img class="icon2" src="@/assets/soicon-7.png" alt="">
                        <div class="para2">
                            沉迷网络<br />叛逆厌学
                        </div>
                    </div>
                    <div class="plate7">
                        <img class="icon2" src="@/assets/soicon-8.png" alt="">
                        <div class="para2">
                            深夜不睡<br />偷玩手机
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section sec5">
            <div class="pack pac5">
                <p class="topic2">
                    实吋管控 家长放心
                </p>
                <p class="topic3">
                    能用什么，能用多久家长决定
                </p>
                <div class="box-10">
                    <div class="plate8">
                        <img class="icon3" src="@/assets/soicon-9.png" alt="">
                        <text>软件管理</text>
                        <p class="para3">
                            设置可用软件<br />下载应用需家长审批
                        </p>
                    </div>
                    <div class="plate8">
                        <img class="icon3" src="@/assets/soicon-10.png" alt="">
                        <text>通话限制</text>
                        <p class="para3">
                            只能对家长添加的<br />号码进行通话
                        </p>
                    </div>
                    <div class="plate8">
                        <img class="icon3" src="@/assets/soicon-11.png" alt="">
                        <text>时间管理</text>
                        <p class="para3">
                            设置应用使用时长<br />定时禁用
                        </p>
                    </div>
                    <div class="plate8">
                        <img class="icon3" src="@/assets/soicon-12.png" alt="">
                        <text>新安装APP限制</text>
                        <p class="para3">
                            新下载的APP一律禁止<br />家长允许才可使用
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="section sec6">
            <div class="pack pac6">
                <p class="topic2">
                    家长可自定义设置 模式的使用时间
                </p>
                <p class="topic3">
                    轻松管控，到时自动切换模式
                </p>
                <div class="box-11">
                    <div class="plate9">
                        <img src="@/assets/soimg-6.png" alt="">
                        <text>学习模式</text>
                    </div>
                    <div class="plate9">
                        <img src="@/assets/soimg-7.png" alt="">
                        <text>娱乐模式</text>
                    </div>
                    <div class="plate9">
                        <img src="@/assets/soimg-8.png" alt="">
                        <text>锁屏模式</text>
                    </div>
                </div>
            </div>
        </div>
        <div class="section sec7">
            <div class="pack pac7">
                <p class="topic2">
                    手机断网 管控依旧生效
                </p>
                <p class="topic3">
                    家长端设置管控功能后，<br />更新后即使孩子断网也会照常执行管控
                </p>
                <img class="pic2" src="@/assets/soimg-10.png">
                <img class="icon4" src="@/assets/soicon-13.png">
                <img class="pic3" src="@/assets/soimg-9.png">
            </div>
        </div>
    </div>
</template>

<script></script>

<style lang="scss" scoped>
* {
    letter-spacing: 2px;
}

// .section {
//     width: 100%;
//     display: flex;
//     justify-content: center;

//     .pack {
//         width: 1146px;
//         height: 100%;
//     }
// }

// banner
.banner {
    background: url("@/assets/banner-6.png") no-repeat;
    background-size: cover;
    background-position: 50% 100%;
}

.topic {
    font-size: 40px;
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: bold;
    color: #000000;
}

.topic2 {
    font-size: 30px;
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: bold;
    color: #CA1C28;
    margin-top: 70px;
}

.topic3 {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #666666;
    margin: 10px 0;
}

// sec1
.sec1 {
    height: 560px;
    // background: #DAEEFB;
    background: linear-gradient(to bottom, #FFFFFF, #DAEEFB);

    .pac1 {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .plate1 {
            text-align: center;
            width: 521px;
            height: 509px;
            background: url("@/assets/soimg-1.png") no-repeat;
            background-size: cover;
            position: relative;

            .topic {
                margin-top: 10px;
            }

            .plate2 {
                width: 370px;
                height: 114px;
                background-color: rgba(227, 232, 239, 1);
                position: absolute;
                top: 160px;
                right: 490px;
                align-items: end;

                &::after {
                    content: '';
                    width: 40px;
                    height: 50px;
                    background-color: rgba(227, 232, 239, 1);
                    clip-path: polygon(10% 0%, 0% 80%, 100% 100%);
                    position: absolute;
                    top: 20px;
                    right: -35px;
                }
            }

            .plate3 {
                width: 260px;
                height: 111px;
                background-color: rgba(227, 232, 239, 1);
                position: absolute;
                top: 310px;
                right: 490px;
                align-items: end;

                &::after {
                    content: '';
                    width: 40px;
                    height: 50px;
                    background-color: rgba(227, 232, 239, 1);
                    clip-path: polygon(0% 20%, 0% 100%, 100% 10%);
                    position: absolute;
                    top: 20px;
                    right: -35px;
                }
            }

            .plate4 {
                width: 280px;
                height: 114px;
                background-color: rgba(227, 232, 239, 1);
                position: absolute;
                top: 200px;
                left: 490px;

                &::after {
                    content: '';
                    width: 40px;
                    height: 50px;
                    background-color: rgba(227, 232, 239, 1);
                    clip-path: polygon(100% 0%, 0% 100%, 100% 80%);
                    position: absolute;
                    top: 20px;
                    left: -38px;
                }

                p {
                    text-align: left;
                }
            }

            .plate5 {
                width: 360px;
                height: 81px;
                background-color: rgba(227, 232, 239, 1);
                position: absolute;
                top: 350px;
                left: 490px;
                align-items: start;

                &::after {
                    content: '';
                    width: 40px;
                    height: 50px;
                    background-color: rgba(227, 232, 239, 1);
                    clip-path: polygon(0% 0%, 100% 100%, 100% 20%);
                    position: absolute;
                    top: 10px;
                    left: -38px;
                }
            }
        }

        .plate2,
        .plate3,
        .plate4,
        .plate5 {
            box-sizing: border-box;
            padding: 10px 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                font-size: 18px;
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: 400;
                color: #333333;

                text {
                    font-size: 24px;
                    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                    font-weight: 800;
                    color: #CA1C28;
                    margin: 0 5px;
                }
            }
        }

        .pic1 {
            width: 247px;
            height: 390px;
            position: absolute;
            bottom: -1px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

// sec2
.sec2 {
    height: 760px;
    background-color: #FFFFFF;

    .topic {
        margin-top: 70px;
        text-align: center;
    }

    .pac2 {
        position: relative;

        .mid1 {
            width: 100%;
            height: 583px;
            display: flex;
            flex-direction: column;
            position: relative;

            .t-img {
                width: 360px;
                height: 206px;
                background: url("@/assets/soimg-3.png") no-repeat;
                background-size: cover;
                font-size: 24px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                box-sizing: border-box;
                padding-top: 145px;
                text-align: center;
                margin-top: 50px;
                margin-left: 40px;
            }

            p {
                width: 360px;
                font-size: 40px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                color: #CA1C28;
                text-align: center;
                margin-left: 40px;
            }

            .two {
                width: 360px;
                height: 40px;
                line-height: 40px;
                font-size: 18px;
                font-family: MicrosoftYaHei;
                color: #CA1C28;
                margin-top: 40px;
                text-align: center;
                margin-left: 40px;
                display: flex;

                .left {
                    position: relative;
                    width: 180px;
                    height: 100%;
                    background-color: rgba(252, 209, 212, 0.5);

                    &::before {
                        content: '';
                        width: 20px;
                        height: 40px;
                        clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
                        background-color: rgba(252, 209, 212, 0.5);
                        position: absolute;
                        top: 0;
                        left: -20px;
                    }
                }

                i {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 0;
                    width: 0;
                    border-style: solid;
                    border-width: 40px 20px 0 0;
                    border-color: rgba(252, 209, 212, 0.1) rgba(255, 191, 195, 0.7) transparent transparent;
                }

                .right {
                    font-size: 18px;
                    font-family: MicrosoftYaHei;
                    color: #CA1C28;
                    width: 180px;
                    height: 100%;
                    background-color: rgba(255, 191, 195, 0.8);
                    position: relative;

                    &::after {
                        content: '';
                        width: 20px;
                        height: 40px;
                        clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
                        background-color: rgba(255, 191, 195, 0.8);
                        position: absolute;
                        top: 0;
                        right: -20px;
                    }
                }
            }

            .mid-img {
                width: 901px;
                height: 583px;
                position: absolute;
                top: -30px;
                right: -160px;
            }
        }

        .below {
            width: 1146px;
            height: 170px;
            background: url("@/assets/banner-7.png") no-repeat;
            background-size: cover;
            border-radius: 12px 12px 0px 0px;
            position: absolute;
            bottom: 0;
            left: 0;

            .tip {
                position: absolute;
                top: -19px;
                left: -1px;
                width: 401px;
                height: 62px;
                line-height: 62px;
                text-align: center;
                background: url("@/assets/qian.png") no-repeat;
                background-size: cover;
                font-size: 30px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                color: #FFFFFF;
            }

            .three {
                margin-top: 70px;
                width: 100%;
                display: flex;
                align-items: center;

                .it {
                    flex: 33.3%;
                    box-sizing: border-box;
                    padding: 0 30px;

                    p {
                        font-size: 20px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        color: #FFFFFF;
                        margin: 6px 0;
                    }

                    p:nth-child(1) {
                        font-weight: bold;
                    }

                    p:nth-child(2) {
                        opacity: 0.7;
                    }

                    &:not(:nth-last-child(1)) {
                        position: relative;

                        &::after {
                            content: '';
                            width: 1px;
                            height: 50px;
                            background-color: #fff;
                            opacity: 0.4;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }
}

// sec3
.sec3 {
    height: 395px;
    background: #F1F4F9;

    .pac3 {
        display: flex;
        flex-direction: column;
        position: relative;

        // .topic2 {
        //     font-size: 30px;
        //     font-family: MicrosoftYaHei, MicrosoftYaHei;
        //     font-weight: bold;
        //     color: #CA1C28;
        //     margin-top: 70px;
        // }

        // .topic3 {
        //     font-size: 16px;
        //     font-family: MicrosoftYaHei;
        //     color: #666666;
        //     margin: 10px 0;
        // }

        .box8 {
            display: flex;
            flex-wrap: wrap;
            width: 840px;
            margin-top: 30px;


            .plate6 {
                display: flex;
                align-items: center;
                height: 60px;
                width: 50%;
                margin-bottom: 20px;

                .icon1 {
                    width: 58px;
                    height: 58px;
                }

                .para {
                    height: 100%;
                    margin-left: 15px;

                    p:nth-child(1) {
                        font-size: 20px;
                        font-family: MicrosoftYaHei;
                        font-weight: bold;
                        color: #333333;
                    }

                    p:nth-child(2) {
                        font-size: 18px;
                        font-family: MicrosoftYaHei;
                        color: #262626;
                        margin: 5px 0;
                    }
                }
            }
        }

        .pac3-img {
            position: absolute;
            right: -100px;
            top: 0;
            width: 501px;
            height: 397px;
        }
    }
}

// sec4
.sec4 {
    width: 100%;
    height: 359px;
    background: #FFFFFF;

    .pac4 {
        text-align: center;

        .box9 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;

            .plate7 {
                width: 24%;
                height: 110px;
                background: #FFFFFF;
                box-shadow: 5px 0px 20px 0px #cecccc;
                border-radius: 6px 6px 6px 6px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon2 {
                    width: 52px;
                    height: 52px;
                }

                .para2 {
                    font-size: 20px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 28px;
                    margin-left: 20px;
                }
            }
        }
    }
}

// sec5
.sec5 {
    width: 100%;
    height: 450px;
    background: url("@/assets/banner-8.png") no-repeat;
    background-size: cover;
    background-position: 50% 100%;

    .pac5 {
        text-align: center;

        .box-10 {
            width: 100%;
            display: flex;
            margin-top: 70px;

            .plate8 {
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: start;

                .icon3 {
                    width: 62px;
                    height: 62px;
                }

                text {
                    margin: 15px 0;
                    font-size: 20px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                }

                p {
                    font-size: 18px;
                    font-family: MicrosoftYaHei;
                    color: #FFFFFF;
                    line-height: 28px;
                    text-align: left;
                    opacity: 0.5;
                    margin-top: 20px;
                }
            }
        }
    }
}

// sec6
.sec6 {
    width: 100%;
    height: 542px;
    background-color: #fff;

    .pac6 {
        text-align: center;

        .box-11 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 50px;

            .plate9 {
                width: 370px;
                height: 250px;
                background: #FFFFFF;
                box-shadow: 1px 0px 20px 0px #dad9d9;
                border-radius: 6px 6px 6px 6px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    width: 352px;
                    height: 182px;
                }

                text {
                    font-size: 20px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: bold;
                    color: #333333;
                    margin: 10px 0
                }
            }
        }
    }
}

// sec7
.sec7 {
    width: 100%;
    height: 405px;
    background: #F1F4F9;
    position: relative;

    .pac7 {
        position: relative;

        .pic2 {
            position: absolute;
            bottom: 0;
            left: 420px;
            width: 1000px;
            height: 351px;
        }
        .icon4{
            position: absolute;
            top: 50%;
            left: 28%;
            transform: translate(0, -50%);
            width: 212px;
            height: 197px;
        }
        .pic3{
            position: absolute;
            top: -30px;
            right: 0;
            width: 302px;
            height: 372px;
        }
    }
}
</style>