import { createRouter, createWebHashHistory } from "vue-router";
import LoginHome from '../components/LoginHome.vue'
import HomeIndex from '../views/Home/HomeIndex.vue'
import HomePage from '../views/Home/HomePage.vue'
import Product from '../views/Prouduct/ProductIndex.vue'
import Solution from '../views/Solution/SolutionIndex.vue'
import Technique from '../views/Technique/TechniqueIndex.vue'
import Job from '../views/Job/JonIndex.vue'
import Contact from '../views/Contact/ContactUs.vue'

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            component: HomeIndex,
            meta: {
                title: '软机'
            },
            children: [
                {
                    path: '',
                    component: HomePage
                },
                {
                    path: 'product',
                    component: Product,
                    meta: {
                        title: '产品'
                    }
                },
                {
                    path: 'solution',
                    component: Solution,
                    meta: {
                        title: '解决方案'
                    }
                },
                {
                    path: 'tech',
                    component: Technique,
                    meta: {
                        title: '技术合作'
                    }
                },
                {
                    path: 'job',
                    component: Job,
                    meta: {
                        title: '工作机会'
                    }
                },
                {
                    path: 'contact',
                    component: Contact,
                    meta: {
                        title: '联系我们'
                    }
                },
            ]
        },
        {
            path: '/login',
            component: LoginHome,
        }
    ],
    scrollBehavior() {
        // 始终滚动到顶部
        return { top: 0 }
    }
})

export default router