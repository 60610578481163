<template>
    <div>
        <div class="top">
            <div class="homenav">
                <RouterLink class="h-logo" to="/"></RouterLink>
                <!-- 导航区域 -->
                <ul class="head-nav">
                    <li>
                        <RouterLink exact-active-class="active1" class="router-link" to="/">首页</RouterLink>
                    </li>
                    <li>
                        <RouterLink exact-active-class="active1" class="router-link" to="/product">产品</RouterLink>
                    </li>
                    <li>
                        <RouterLink exact-active-class="active1" class="router-link" to="/solution">解决方案</RouterLink>
                    </li>
                    <li>
                        <RouterLink exact-active-class="active1" class="router-link" to="/tech">技术合作</RouterLink>
                    </li>
                    <li>
                        <RouterLink exact-active-class="active1" class="router-link" to="/job">工作机会</RouterLink>
                    </li>
                    <li>
                        <RouterLink exact-active-class="active1" class="router-link" to="/contact">联系我们</RouterLink>
                    </li>
                </ul>
            </div>
        </div>
        <div class="container">
            <RouterView></RouterView>
        </div>
        <!-- footer -->
        <div class="foot">
            版权所有：北京金安明信科技有限公司 ©2023 软机 <a href="https://beian.miit.gov.cn">京ICP备13016439号-15</a>
        </div>

        <!-- 固定板块 -->
        <div class="fw">
            <div class="fw-it" @mouseover="showHover = true" @mouseout="showHover = false">
                <img src="@/assets/jiaoliu.png" alt="">
                <text>产品交流</text>
                <div class="box2" v-show="showHover">
                    <div class="box3">
                        <img src="@/assets/code1.png" alt="">
                    </div>
                    <div class="triangle"></div>
                </div>
            </div>
            <div class="fw-it" @mouseover="showHover2 = true" @mouseout="showHover2 = false">
                <img src="@/assets/shenqing.png" alt="">
                <text>申请试用</text>
                <div class="box2" v-show="showHover2">
                    <div class="box3">
                        <img src="@/assets/code2.jpg" alt="">
                    </div>
                    <div class="triangle"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showHover: false,
            showHover2: false,
        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.top,
.container,
.foot {
    min-width: 1250px;
}

.top {
    // width: 1600px;
    width: 100%;
    height: 64px;
    background: #AB101B;
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 999;
}

.homenav {
    width: 1148px;
    height: 100%;
    display: flex;
    align-items: center;
}

.h-logo {
    display: inline-block;
    width: 130px;
    height: 28px;
    background: url("../../assets//logo2.png") no-repeat;
    background-size: cover;
}

.head-nav {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 160px;

    li {
        width: 100px;
        height: 100%;

        .router-link {
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            color: #FFFFFF;
            width: 100px;
            height: 100%;

            &:hover {
                background: #8E030D;
            }
        }

        .active1 {
            background: #8E030D;
        }
    }


}

.container {
    width: 100%;
    background: #F2F6FA;
    margin-top: 64px;
}

.fw {
    width: 122px;
    height: 109px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    box-shadow: 10px -10px 10px -10px #a8a7a7;
    position: fixed;
    top: 50%;
    right: 10px;
    display: flex;
    flex-direction: column;

    .fw-it {
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        color: #4D4D4D;
        position: relative;

        &>img {
            width: 35px;
            height: 30px;
        }

        &:first-of-type {
            border-bottom: 1px solid #D9D9D9;
        }

        &:hover {
            color: #AB101B;
            background-color: #E6EBF2;
            cursor: pointer;
        }

        .box2 {
            width: 150px;
            height: 150px;
            background: #FFFFFF;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 20px;
            position: absolute;
            top: 0;
            left: -160px;
            z-index: 999;

            &:hover {
                cursor: pointer;
            }

            .box3 {
                background: rgba(0, 0, 0, 0.2);
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .triangle {
                position: absolute;
                width: 0;
                height: 0;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 8px solid #FFFFFF;
                top: 30px;
                right: -8px;
                transform: translateY(-50%);
            }
        }
    }
}

.foot {
    width: 100%;
    height: 60px;
    background: #2C3038;
    line-height: 60px;
    text-align: center;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;

    // position: fixed;
    // bottom: 0;
    a {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>