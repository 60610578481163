<template>
    <div>
        <div class="banner section">
            <div class="pack">
                <div class="char3" style="margin-top: 30px;">
                    「 工作机会 」
                </div>
                <div class="char4">
                    加入我们
                </div>
                <p class="char5">
                    我们是一家以安卓机器人流程自动化管理平台。<br />
                    我们致力于在安卓手机上实现RPA功能具有很好的市场前景。<br />
                    提升用户在使用软件机器人产品和服务中的体验。<br />
                    我们都在期待你的加入!
                </p>
                <p class="char5" style="margin-top: 10px;">邮箱：hr@jinsec.com</p>
            </div>
        </div>
        <div class="section frame7">
            <div class="pack pot7">
                <div class="head" @click="openDetail(0)">
                    <text>安卓开发</text>
                    <img :class="list[0].show ? 'show': ''" src="@/assets/zhankai.png" alt="">
                </div>
                <div class="jboxs" :style="{display: list[0].show ? `block`:`none`}">
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>工作内容</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.安卓RPA交互模块定制化开发;<br />
                            2.目标APP混淆代码分析;<br />
                            3.为安卓RPA撰写自动化规则;<br />
                            4.本地客户落地应用实施。
                        </div>
                    </div>
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>任务要求</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.安卓开发框架有使用经验;<br />
                            2.对Framework比较了解;<br />
                            3.热爱技术，能主动学习进步;<br />
                            4.可接受零经验，有导师带。
                        </div>
                    </div>
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>特别说明</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.兼职为主。合作顺利，可以转全职;<br />
                            2薪酬按模块工作量。也可以协商月薪制。<br />
                        </div>
                    </div>
                </div>

                <div class="head" @click="openDetail(1)">
                    <text>数据采集</text>
                    <img :class="list[1].show ? 'show': ''" src="@/assets/zhankai.png" alt="">
                </div>
                <div class="jboxs" :style="{display: list[1].show ? `block`:`none`}">
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>工作内容</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.基于公司安卓RPA软件为本地客户开发定制化模块;<br />
                            2.使用公司软件为客户采集数据（微信群自主接单）;<br />
                            3.为公司安卓RPA软件撰写采集规则介绍文档<br />
                            4.扩展公司安卓RPA软件的采集场景。
                        </div>
                    </div>
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>任务要求</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.了解开源爬虫系统，有大数据处理经验;<br />
                            2.对安卓逆向、脱壳有一定了解;<br />
                            3.有数据采集经验，或对该行业热爱，有学习热情;<br />
                            4.愿意学习的零经验也可以培养。
                        </div>
                    </div>
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>特别说明</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.兼职为主。合作顺利，可以转全职;<br />
                            2薪酬按模块工作量。也可以协商月薪制。<br />
                        </div>
                    </div>
                </div>

                <div class="head" @click="openDetail(2)">
                    <text>市场推广</text>
                    <img :class="list[2].show ? 'show': ''" src="@/assets/zhankai.png" alt="">
                </div>
                <div class="jboxs" :style="{display: list[2].show ? `block`:`none`}">
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>工作内容</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.负责社群的推广引流;<br />
                            2.可以线上做，也可以结合线下做;<br />
                            3.线上主要是微信群拉新，线下主要是社区拉新;<br />
                            4.积极跟踪市场反馈，拓展其它渠道。
                        </div>
                    </div>
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>任务要求</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.有相关市场推广经验;<br />
                            2.对引流拉新相关工作比较了解;<br />
                            3.性格外向，喜欢与人打交道;<br />
                            4.时间自由灵活，能按时完成任务。
                        </div>
                    </div>
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>特别说明</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.兼职为主。合作顺利，可以转全职。<br />
                            2.薪酬按销售提成。
                        </div>
                    </div>
                </div>

                <div class="head" @click="openDetail(3)">
                    <text>PHP开发</text>
                    <img :class="list[3].show ? 'show': ''" src="@/assets/zhankai.png" alt="">
                </div>
                <div class="jboxs" :style="{display: list[3].show ? `block`:`none`}">
                    <div class="j-plate">
                        <div class="j-box">
                            1.我们根据用户需求，把开发任务切分成不同的模块;<br />
                            2.每个开发工程师只做自己熟悉的（最好以前开发过的）模块;<br />
                            3.这样可以尽量减少工作量，避免每个模块都从头实现;<br />
                            4.我们的目标是用AIGC做代码生成和代码共享。<br/>
                            5.尽量减少开发过程中重复造轮子的问题。
                        </div>
                    </div>
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>工作内容</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.根据用户及公司需求，负责php端的开发;<br />
                            2.负责产品系统的开发，编写、接口的开发;<br />
                            3.负责网站的前后端设计开发、主题模板开发或二次修改;<br />
                            4.公司安卓RPA产品的交互模块开发。
                        </div>
                    </div>
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>任职要求</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.熟悉基于PHP的面向对象编程思想;<br />
                            2.熟悉至少一种PHP框架，Laravel、Thinkphp、Drupal等;<br />
                            3.有过开源软件二次开发经验，比如wordpress、opencart、magento等;<br />
                            4.熟悉 mysql 数据库及 T-SQL 脚本，了解mysql 注入和防范知识。<br/>
                            5.了解nginx、apache、redis、主流MQ;<br/>
                            6.若零经验愿意学习有导师带。
                        </div>
                    </div>
                    <div class="j-plate">
                        <div class="j-ln">
                            <text>特别说明</text>
                            <img class="right" src="@/assets/right.png" alt="">
                        </div>
                        <div class="j-box">
                            1.微信群接单模式，合适可以转全职。<br />
                            2.薪酬按模块工作量。也可以协商月薪制。<br />
                        </div>
                    </div>
                </div>

                <div class="head" @click="openDetail(4)">
                    <text>社群运营</text>
                    <img :class="list[4].show ? 'show': ''" src="@/assets/zhankai.png" alt="">
                </div>
                <div class="jboxs" :style="{display: list[4].show ? `block`:`none`}">
                    <div class="j-plate">
                        <div class="j-box">
                            1.可以兼职。<br />
                            2.在小区就能做，发展前景好。<br />
                            3.社群主要基于一定的场景，为聚合用户提供增值服务。场景可以基于地理位置，比如一个小区、一个学校。或者是基于某个组织，比如居委会、社保机构等。在碎片化的时间内，为低频需求提供更好的服务。<br />
                            4.现招聘社群合作伙伴，优势互补资源变现。<br/>
                            5.工作非常简单，占用时间少，在家花一点时间就能完成。还可以积攒各行业的人脉资源。
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    data() {
        return{
            open: false,
            list: ref([
                {
                    id: 0,
                    name: '安卓开发',
                    show: true,
                },
                {
                    id: 1,
                    name: '数据采集',
                    show: false,
                },
                {
                    id: 2,
                    name: '市场推广',
                    show: false,
                },
                {
                    id: 3,
                    name: '商务专员',
                    show: false,
                },
                {
                    id: 4,
                    name: '社群运营',
                    show: false,
                }
            ])
        }
    },
    methods: {
        openDetail(val) {
            let that = this
            that.list[val].show = !that.list[val].show
        }
    },
    mounted() {
        
    }
}
</script>

<style lang="scss" scoped>
.banner {
    background: url("@/assets/banner-13.png") no-repeat;
    background-size: cover;
    background-position: 50% 100%;
}

.frame7 {
    background-color: #fff;
}

.pot7 {
    box-sizing: border-box;
    padding: 60px 0;
    display: flex;
    flex-direction: column;
}

.head {
    width: 100%;
    height: 60px;
    background: #EDF0F5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    &:hover{
        background: #d9dce0;
        cursor: pointer;
    }

    text {
        display: inline-block;
        font-size: 30px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        color: #000000;
        margin-left: 30px;
    }

    img {
        width: 60px;
        height: 60px;
    }

    .show{
        rotate: 180deg;
    }
}

.jboxs {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;

    .j-plate {
        width: 100%;
        box-sizing: border-box;
        padding: 16px 0;

        &:not(:nth-last-child(1)) {
            position: relative;

            &::after {
                content: '';
                width: 100%;
                height: 1px;
                background: #DADCE0;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
}

.j-ln {
    display: flex;
    align-items: center;

    text {
        font-size: 18px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #CA1C28;
    }

    img {
        width: 10px;
        height: 16px;
        margin-left: 8px;
    }
}

.j-box {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #404040;
    line-height: 30px;
    margin-top: 19px;
}
</style>