<template>
    <div>
        <div class="banner section">
            <div class="pack">
                <div class="char3">
                    「 技术合作 」
                </div>
                <div class="char4">
                    软机与日常生活
                </div>
                <p class="char5">
                    已经展现了未来充满想象的发展空间。可广发应用于安全检测、数据采集、任务执行、辅助办公、K12教育、游戏防沉溺、养老助残等多种行业。
                </p>
            </div>
        </div>
        <div class="section frame1">
            <div class="pack pot1">
                <p class="title-t">AOSP安卓系统定制</p>
                <p class="chapter1" style="margin-top: 40px;">
                    AOSP (Android Open Source Project)是安卓系统的开源项目，通过开放源代码，使得开发者可以自由地修改、定制和适配安卓系统。
                </p>
                <p class="chapter1">
                    AOSP的开放性为我们的软机R产品开发自主操作系统提供了坚实的基础。
                </p>
                <p class="chapter1">
                    我们已经基于AOSP进行了针对RPA引擎的二次开发，后续进一步定制适合自身产品和市场需求的操作系统。
                </p>
                <img class="t-img1" src="@/assets/timg-1.png" alt="">
            </div>
        </div>
        <div class="section frame2">
            <div class="pack pot2">
                <p class="title-t">行业场景落地</p>
                <div class="t-boxs">
                    <div class="tbox1">
                        <div class="h-box">
                            知识产权侵权线索
                        </div>
                        <p>
                            知识产权被侵权可以委托律所向工商行政管理部门投诉并申请行政查处知识产权侵权行为，律师需要搜集、保存对方侵犯所享有的知识产权的证据。但目前很多侵权商品都是在各类APP上销售，搜集成本比较大。使用软件机器人的自动化RPA脚本，可以完全代替人工进行操作。为律师处理侵权案件带来很多便利。
                        </p>
                    </div>
                    <div class="tbox1">
                        <div class="h-box">
                            APP自动化测试
                        </div>
                        <p>
                            随着移动应用的迅速发展，APP的自动化测试工具变得越来越重要。APP自动化测试工具可以帮助开发者在应用程序的不同平台上执行测试，从而检测和确保应用程序的完整性、稳定性和可靠性。
                            <br/>
                            传统的测试框架，在实现上有各种不足。基于软件机器人可以更方便的撰写测试脚本，并且能对APP运行的全过程进行检测。从框架的适用性和扩展性来说，都是一个比较好的行业落地应用。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="section frame3">
            <div class="pack pot3">
                <p class="title-t" style="color: #FFFFFF;">机机交互模块</p>
                <p class="chapter2">
                    人机交互是指人和计算机之间进行信息交流和互动的过程，旨在打造更智能、更人性化的体验。随着技术的发展和智能化的进步，人机交互正在不断演进和改善，为用户提供更便捷、更自然的交互方式和体验。<br /><br />
                    机机交互与人机交互不同的是，通过智能助手代替人与机器进行交互。原来人参与的交互行为，都由每个人的智能助理（数字人）代替进行智能决策。通过机机交互模块，可以把宿主人的需求，直接与现有的各类程序对接，完成日常生活中的各类需求，从而把人工智能融入到现实生活中。
                </p>
            </div>
        </div>
        <div class="section frame4">
            <div class="pack pot4">
                <p class="title-t" style="text-align: center;">API接口服务</p>
                <p>
                    API接口服务可帮助合作伙伴实现数据交互和信息共享。<br />
                    它可以将软件机器人内部数据与外部系统进行连接和对接，实现数据的传输和共享。<br />
                    在当今人工智能时代，API数据接口服务已经成为智能程序不可或缺的一部分。
                </p>
                <div class="tbox2">
                    <p class="word">数据共享与协同</p>
                    <text>API数据接口服务可以帮助合作伙伴将不同系统中的数据进行共享和协同。</text>
                    <p class="chapter3">
                        通过API，各个系统可以实时地获取和更新数据，使得不同部门之间的协作更加高效，并且可以避免数据冗余和不一致的问题。
                    </p>
                </div>
                <img class="t-img2" src="@/assets/timg-2.png" alt="">
            </div>
        </div>
        <div class="section frame5">
            <div class="pack pot5">
                <div class="tbox2">
                    <p class="word">服务扩展与创新</p>
                    <text>API数据接口服务可以帮助合作伙伴扩展其产品和服务的范围。</text>
                    <p class="chapter3">
                        通过对外提供API接口，软件机器人可以开放其核心功能给合作伙伴使用，使得合作伙伴能够基于软件机器人的服务开发各种创新应用。
                    </p>
                </div>
                <img class="t-img3" src="@/assets/timg-3.png" alt="">
            </div>
        </div>
        <div class="section frame6">
            <div class="pack pot6">
                <div class="tbox2">
                    <p class="word">提升用户体验</p>
                    <text>API数据接口服务可以提升用户在使用软件机器人产品和服务中的体验。</text>
                    <p class="chapter3">
                        通过开放API接口，软件机器人可以与合作伙伴共享用户数据，实现个性化推荐和精准营销，从而提供更好的用户体验,
                    </p>
                </div>
                <img class="t-img4" src="@/assets/timg-4.png" alt="">
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
// 字体
.title-t,
.chapter1,
.h-box,
.chapter2 {
    font-family: MicrosoftYaHei;
}

.banner {
    background: url("@/assets/banner-9.png") no-repeat;
    background-size: cover;
    background-position: 50% 100%;
}

.title-t {
    font-size: 40px;
    font-weight: bold;
    margin-top: 60px;
}

// frame1
.frame1 {
    width: 100%;
    height: 530px;
    background: url("@/assets/banner-10.png") no-repeat;
    background-size: cover;
    background-position: 100% 100%;
    background-color: #FCFCFC;

    .pot1 {
        display: flex;
        flex-direction: column;
        position: relative;

        .chapter1 {
            width: 650px;
            height: auto;
            background: #EDF0F5;
            border-radius: 6px 6px 6px 6px;
            box-sizing: border-box;
            padding: 19px;
            font-size: 18px;
            color: #333333;
            line-height: 30px;
            margin: 10px 0;
        }

        .t-img1 {
            width: 408px;
            height: 417px;
            position: absolute;
            top: 60px;
            right: 0;
        }
    }
}

// frame2
.frame2 {
    width: 100%;
    height: 660px;
    background: url("@/assets/banner-11.png") no-repeat;
    background-size: 2002px 452px;
    background-position: bottom;
    background-color: #fff;

    .pot2 {
        text-align: center;

        .t-boxs {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 40px;

            .tbox1 {
                width: 564px;
                height: 464px;
                background: #FFFFFF;
                border-radius: 0px 6px 6px 0px;
                border: 1px solid #E4E6EB;
                box-sizing: border-box;
                padding: 15px 9px;
                position: relative;

                .h-box {
                    width: 544px;
                    height: 160px;
                    box-sizing: border-box;
                    background: url("@/assets/timg-5.png") no-repeat;
                    background-size: cover;
                    font-size: 20px;
                    font-weight: bold;
                    color: #FFFFFF;
                    padding: 120px 18px 0;
                    text-align: left;
                }

                p {
                    width: 494px;
                    font-size: 18px;
                    margin: 0 auto;
                    font-family: MicrosoftYaHei;
                    color: #262626;
                    line-height: 30px;
                    text-align: left;
                    margin-top: 20px;
                }

                &::before {
                    content: '';
                    width: 564px;
                    height: 6px;
                    background-color: #CA1C28;
                    position: absolute;
                    top: 0;
                    left: -1px;
                }
            }


        }
    }
}

// frame3
.frame3 {
    width: 100%;
    height: 480px;
    background: url("@/assets/banner-12.png") no-repeat;
    background-size: 100% 101%;
    background-position: center;

    .pot3 {
        .chapter2 {
            width: 607px;
            font-size: 18px;
            font-family: MicrosoftYaHei;
            color: #FFFFFF;
            line-height: 30px;
            opacity: 0.7;
            margin-top: 50px;
            position: relative;

            &::before {
                content: '';
                width: 40px;
                height: 36px;
                background: url("@/assets/ticon-1.png") no-repeat;
                background-size: cover;
                position: absolute;
                top: -20px;
                left: -60px;
            }

            &::after {
                content: '';
                width: 40px;
                height: 36px;
                background: url("@/assets/ticon-2.png") no-repeat;
                background-size: cover;
                position: absolute;
                bottom: -20px;
                right: -40px;
            }
        }
    }
}

// frame4
.frame4 {
    width: 100%;
    height: 540px;
    background-color: #fff;

    .pot4 {
        position: relative;

        .tbox2 {
            margin-top: 20px;
        }

        p {
            &:nth-child(2) {
                font-size: 18px;
                font-family: MicrosoftYaHei;
                color: #262626;
                line-height: 30px;
                margin-top: 30px;
                text-align: center;
            }
        }

        .t-img2 {
            width: 457px;
            height: 322px;
            position: absolute;
            bottom: 0;
            right: -30px;
        }
    }
}

.tbox2 {
    width: 620px;
    font-family: MicrosoftYaHei;
    display: flex;
    flex-direction: column;

    .word {
        font-size: 24px;
        font-weight: bold;
        color: #CA1C28;
        margin-top: 62px;
    }

    text {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top: 20px;
    }

    .chapter3 {
        width: 100%;
        font-size: 18px;
        color: #262626;
        line-height: 30px;
    }
}

// frame5
.frame5 {
    width: 100%;
    height: 260px;
    background: #F0F3F8;

    .pot5 {
        position: relative;

        .tbox2 {
            position: absolute;
            top: 0;
            right: 0;
        }

        .t-img3 {
            width: 464px;
            height: 262px;
            position: absolute;
            top: 0;
            left: -70px;
        }
    }
}

// frame6
.frame6 {
    width: 100%;
    height: 300px;
    background-color: #fff;

    .pot6 {
        position: relative;

        .t-img4 {
            width: 454px;
            height: 301px;
            position: absolute;
            top: 0;
            right: -30px;
        }
    }
}</style>