<template>
  <div class="sec-login">
    <div class="imgBox">
      <img src="@/assets/img.png" alt="">
      <div class="title-text">
        <span>软机
          <span>®</span>
          <span>安卓机器人流程自动化管理平台</span>
        </span>
      </div>
    </div>
    <div class="content">
      <img class="logo" src="@/assets/logo.png" alt="">
      <div class="title">登录</div>
      <div class="user">
        <img src="@/assets/icon(1).png" alt="">
        <input class="text" type="text" name="username" placeholder="请输入用户名">
      </div>
      <div class="line"></div>
      <div class="password">
        <img src="@/assets/icon.png" alt="">
        <input class="text" type="password" name="password" placeholder="请输入密码">
      </div>
      <div class="line"></div>
      <div class="checkbox">
        <input class="check" type="checkbox"><span>记住我</span>
      </div>
      <button type="submit">登录</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoginHome',
  props: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped> * {
   margin: 0;
   padding: 0;
 }

 .sec-login {
   box-sizing: border-box;
   position: relative;
   width: 100%;
   height: 100vh;
   display: flex;
   min-width: 1600px;
   min-height: 800px;

   .imgBox {
     position: relative;
     width: 58%;
     height: 100%;

     img {
       width: 100%;
       height: 100%;
       background-color: #AB101B;
     }

     .title-text {
       background-color: pink;
        color: #FFFFFF;

       span:nth-child(1) {
         display: block;
         font-size: 52px;
         font-weight: 800;
         font-family: PingFang SC;
         width: 108px;
         height: 52px;
         line-height: 52px;
         position: absolute;
         top: 67.5%;
         left: 8.5%;

         span:nth-child(1) {
           display: block;
           width: 20px;
           height: 20px;
           line-height: 20px;
           font-size: 20px;
           font-weight: 600;
           position: relative;
           top: -60px;
           left: 114px;
         }

         span:nth-child(2) {
           display: block;
           width: 495px;
           height: 35px;
           font-size: 35px;
           font-family: PingFang SC;
           font-weight: 600;
           line-height: 35px;
           position: relative;
           top: 38px;
           left: 0;
         }
       }
     }
   }

   .content {
     width: 42%;
     height: 100%;
     position: relative;

     .logo {
       position: absolute;
       right: 50px;
       top: 50px;
     }

     .title {
       width: 80px;
       height: 35px;
       font-size: 35px;
       font-family: PingFang SC;
       font-weight: 600;
       color: #AB101B;
       line-height: 35px;
       margin-top: 175px;
       margin-left: 120px;
     }

     .user {
       margin-top: 119px;
       display: flex;
       justify-content: flex-start;
       margin-left: 120px;

       .text {
         width: 118px;
         height: 20px;
         font-size: 20px;
         font-family: PingFang SC;
         font-weight: 400;
         color: #8C8C8C;
         border: none;
         outline: none;
         margin-left: 5px;
       }
     }

     .line {
       width: 69%;
       height: 1px;
       background: #D9D9D9;
       margin: auto;
       margin-top: 25px;
     }

     .password {
       margin-top: 53px;
       display: flex;
       justify-content: flex-start;
       margin-left: 120px;

       .text {
         width: 118px;
         height: 20px;
         font-size: 20px;
         font-family: PingFang SC;
         font-weight: 400;
         color: #8C8C8C;
         border: none;
         outline: none;
         margin-left: 5px;
       }
     }

     .checkbox {
       height: 20px;
       line-height: 20px;
       margin-top: 20px;
       display: flex;
       justify-content: flex-start;
       margin-left: 120px;

       .check {
         width: 20px;
         height: 20px;
         background: #CCCCCC;
         border: #CCCCCC;
       }

       span {
         display: inline-block;
         height: 20px;
         font-size: 20px;
         font-family: PingFang SC;
         font-weight: 400;
         color: #4B4C4C;
         margin-left: 12px;
       }
     }

     button {
       width: 69%;
       height: 60px;
       background: #AB101B;
       border-radius: 30px;
       border: 0;
       font-size: 20px;
       color: #FFFFFF;
       font-family: PingFang SC;
       font-weight: 600;
       margin-top: 69px;
       margin-left: 120px;
     }
   }
 }
</style>
