<template>
  <!-- 一级路由出口组件 -->
  <RouterView>
  </RouterView>
</template>

<script>
// 创建一个计时器变量
let timer = null
let confirmation = null
export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      // 初始化时间为0秒
      seconds: 0
    }
  },
  methods: {
    // 判断是否是手机端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|ios)/i)
      return flag
    },
    startTimer(){
      // console.log('开始24小时判断');
      clearInterval(timer)
      timer = setInterval(() => {
        if(this.seconds == 86400){
          this.seconds = 0
          // console.log('重新计时判断');
          this.judgeDevice()
        }else{
          this.seconds++ 
        }
      }, 1000)
    },
    judgeDevice() {
      confirmation = confirm('目前访问的是电脑版，需要跳转到移动版吗？')
      if (confirmation) {
        window.location.replace('https://m.soft-bot.com')
      } else {
        this.startTimer()
      }

    }
  },
  mounted() {
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
      this.judgeDevice()
    } else {
      return
    }
  },
  unmounted() {
    clearInterval(timer)
    this.seconds = null
  }
}
</script>

<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // margin: 0;
}

* {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.banner {
  width: 100%;
  height: 322px;
  margin-top: -1px;
}

.section {
  width: 100%;
  display: flex;
  justify-content: center;

  .pack {
    width: 1146px;
    height: 100%;
  }
}

.char3 {
  font-size: 30px;
  font-weight: bold;
  color: #AB101B;
  margin-top: 80px;
}

.char4 {
  font-size: 34px;
  font-weight: bold;
  color: #262626;
  margin: 20px 0;
}

.char5 {
  width: 440px;
  font-size: 16px;
  color: #666666;
  line-height: 24px;
}
</style>
